import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import * as TYPES from "../constants/actionTypes";
import { SurveyActions, SurveyAnswerParameters } from "@shared/types";
import styled from "styled-components";
import { Popup } from "./Common/Popup";
import icon_close from "../assets/icon-close-dark.svg";

const mapDispatchToProps = (dispatch: any) => {
  return {
    surveyOpened: (token: string, surveyKey: string) => {
      dispatch({
        type: TYPES.USER_SURVEY_ACTION,
        payload: {
          data: {
            action: SurveyActions.OPENED,
            surveyKey,
          },
          token,
        },
      });
    },
    surveyAnswered: (
      token: string,
      surveyKey: string,
      parameters: SurveyAnswerParameters
    ) => {
      dispatch({
        type: TYPES.USER_SURVEY_ACTION,
        payload: {
          data: {
            action: SurveyActions.ANSWERED,
            surveyKey,
            parameters,
          },
          token,
        },
      });
    },
    surveyDismissed: (token: string, surveyKey: string) => {
      dispatch({
        type: TYPES.USER_SURVEY_ACTION,
        payload: {
          data: {
            action: SurveyActions.DISMISSED,
            surveyKey,
          },
          token,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    sessionCount: state.users.sessionCount,
    survey: state.users.survey,
    token: state.token.key,
  };
};

interface SurveyProps {
  survey: {
    key: string;
    popupDelay: number;
    popupTitle: string;
    popupText: string;
  };
  surveyOpened(surveyKey: string, token: string): void;
  surveyAnswered(
    surveyKey: string,
    token: string,
    parameters: SurveyAnswerParameters
  ): void;
  surveyDismissed(surveyKey: string, token: string): void;
  token: string;
}

const SurveyPopupBubble = styled.div<{
  animate: boolean;
  expanded: boolean;
}>`
  z-index: 99;
  border: none;
  background: white;
  position: fixed;
  right: 80px;
  cursor: pointer;
  bottom: 20px;

  width: ${(props) => (props.expanded ? "400px" : "90px")};
  height: ${(props) => (props.expanded ? "auto" : "90px")};
  padding: ${(props) => (props.expanded ? "16px 24px" : "0")};

  background: white;

  // It's subtle, but the expanded bubble looks better with slightly less rounded corners
  border-radius: ${(props) => (props.expanded ? "20px" : "45px")};

  box-shadow: -2px 5px 10px rgba(0, 0, 0, 0.5);

  transition: width 400ms, border-radius 400ms;

  // Animate a pulse around the bubble
  ${(props) =>
    props.animate &&
    `
    outline: 2px solid rgba(0,0,0,0.0);
    outline-offset: 40px;
    animation-name: outline-pulse;
    animation-duration: 8s;
    animation-iteration-count: infinite;
  `}

  @keyframes outline-pulse {
    0% {
      outline-color: rgba(0, 0, 0, 0.15);
      outline-offset: 0;
    }

    50% {
      outline-color: rgba(0, 0, 0, 0);
      outline-offset: 40px;
    }
  }

  // Animate the popup bubble sliding in from below
  animation-name: slide-in;
  animation-duration: 1s;
  animation-iteration-count: 1;

  @keyframes slide-in {
    from {
      bottom: calc(-100vh - 200px);
    }

    to {
      bottom: 20px;
    }
  }
`;
const SurveyPopupTitle = styled.h3`
  text-align: left;
`;
const SurveyPopupText = styled.div`
  font-size: 14px;
  text-align: left;
  margin: 20px 0 30px;
`;
const SurveyActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  margin-bottom: 12px;
`;
const SurveyButtonBase = styled.button`
  padding: 6px 12px;
  background: none;
  font-weight: 700;

  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
const SurveyOpenButton = styled(SurveyButtonBase)`
  background: var(--seafoam-blue);
  color: white;
`;
const SurveyDismissButton = styled(SurveyButtonBase)`
  border: 1px solid var(--cool-blue);
  background: white;
  color: var(--twilight-blue);
`;
const SurveyCloseButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const SurveyIframeContainer = styled.div`
  z-index: 100;
  margin-top: 10px;

  > iframe {
    border: none;
  }
`;
const SurveyIcon = styled.div`
  text-align: center;
  img {
    height: 40px;
    opacity: 0.6;
  }
`;

export function SurveyPopupComponent(props: SurveyProps) {
  const { t } = useTranslation();
  const {
    survey,
    surveyOpened,
    surveyAnswered,
    surveyDismissed,
    token,
  } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);

  const surveyKey = survey.key;
  const { popupDelay, popupTitle, popupText } = survey;

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!showPopup) {
      setTimeout(() => {
        setShowPopup(true);
      }, popupDelay * 1000);
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handler = (event: any) => {
      if (event.origin === "https://survey.zef.fi") {
        if (event.data.type === "complete") {
          const { answererId, linkKey, team } = event.data.data;
          surveyAnswered(token, surveyKey, {
            answererId,
            linkKey,
            team,
          });
        } else if (event.data.type === "start") {
          resetSurvey();
        }
      }
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenSurvey = () => {
    surveyOpened(token, surveyKey);
    setShowSurvey(true);
    resetSurvey();
  };

  const onCloseSurvey = () => {
    setShowSurvey(false);
  };

  // zef.fi implements some kind of fingerprinting/session functionality, which occasionally causes a
  // survey to be pre-filled with the answers from an earlier instance of the same survey.
  // We can reset the survey with the following function.
  const resetSurvey = () => {
    if (iframeRef.current) {
      iframeRef?.current?.contentWindow?.postMessage({ restart: true }, "*");
    }
  };

  // zef.fi does not implement a "survey closed" message, so we have to implement it ourselves.
  const onDismissSurvey = () => {
    surveyDismissed(token, surveyKey);
  };

  const iframeContent = (
    <SurveyIframeContainer>
      <iframe
        title="Survey"
        src={`https://survey.zef.fi/${surveyKey}`}
        width="100%"
        height="480px"
        ref={iframeRef}
      ></iframe>
    </SurveyIframeContainer>
  );

  if (!showPopup) {
    return null;
  }
  return (
    <>
      <Popup
        isOpen={showSurvey}
        handleClose={onCloseSurvey}
        content={iframeContent}
      >
        {iframeContent}
      </Popup>
      <SurveyPopupBubble animate={!showSurvey} expanded={!showSurvey}>
        {!showSurvey ? (
          <>
            <SurveyPopupTitle>{popupTitle}</SurveyPopupTitle>
            <SurveyPopupText>{popupText}</SurveyPopupText>
            <SurveyActionButtons>
              <SurveyDismissButton onClick={onDismissSurvey}>
                {t("surveys.popup.later")}
              </SurveyDismissButton>
              <SurveyOpenButton onClick={onOpenSurvey}>
                {t("surveys.popup.ok")}
              </SurveyOpenButton>
            </SurveyActionButtons>
          </>
        ) : (
          <SurveyCloseButton onClick={onCloseSurvey}>
            <SurveyIcon>
              <img src={icon_close} alt="Close survey" />
            </SurveyIcon>
          </SurveyCloseButton>
        )}
      </SurveyPopupBubble>
    </>
  );
}

export const SurveyPopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyPopupComponent);
