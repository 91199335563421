import { DeviceErrorTaskStates } from "@shared/types";
import { useMemo } from "react";
import { possibleDeviceErrorTaskStates } from "../../../../../../server/src/shared/constants";
import { Table } from "../../../../components/Common/Table";
import { Toggle } from "../../../../components/Common/Toggle";
import { triggerStateToFormString } from "./constants";

type DeviceRow = {
  id: number;
  serialNumber: string;
  ownerGroup: string | null;
  site: string | null;
  triggerStates: Map<DeviceErrorTaskStates, boolean>;
  owner: "self" | "child";
};
type ErrorTaskDeviceTableProps = {
  onToggle: (
    deviceId: number,
    state: DeviceErrorTaskStates,
    value: boolean
  ) => void;
  devices: DeviceRow[];
  includeChildGroupDevices: boolean;
};

export function ErrorTaskDeviceTable(props: ErrorTaskDeviceTableProps) {
  // Prevent re-rendering all device table rows for each change in the containing form
  const tableDevices = useMemo(
    () =>
      props.includeChildGroupDevices
        ? props.devices
        : props.devices.filter((devStates) => devStates.owner === "self"),
    [props.devices, props.includeChildGroupDevices]
  );

  const { onToggle } = props;
  const columns = useMemo(() => {
    return [
      {
        Header: "Device",
        accessor: (row: DeviceRow) =>
          row.site ? `${row.serialNumber} - ${row.site}` : row.serialNumber,
        id: "device",
        width: 5,
      },
      { Header: "Group", accessor: "ownerGroup", width: 3 },
      ...possibleDeviceErrorTaskStates.map((state) => ({
        Header: triggerStateToFormString[state],
        id: state,
        width: 2,
        Cell: ({ row }: any) => (
          <Toggle
            selected={row.original.triggerStates.get(state)}
            onToggle={() => {
              onToggle(
                row.original.id,
                state,
                !row.original.triggerStates.get(state)
              );
            }}
          />
        ),
      })),
    ];
  }, [onToggle]);

  if (props.devices.length === 0) {
    return <div>There are no devices</div>;
  }

  return (
    <Table
      data={tableDevices}
      infiniteScroll
      initialState={{ sortBy: [{ id: "device" }] }}
      sortable
      columns={columns}
    />
  );
}
