import styled from "styled-components";
import { Formik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { FlexBoxWrapper } from "../../components/Common";
import { RoundDenimButton } from "../../components/Common/Button";
import { ConfirmationModal } from "../../components/Common/ConfirmationModal";
import { FormBackground } from "../../components/Common/FormBackground";
import {
  FormInput,
  FormLabel,
  FormToggle,
  FormValue,
  InputBox,
} from "../../components/Common/FormInput";
import { FormRectangle as FormRectangleRaw } from "../../components/Common/FormRectangle";
import { FormSubmitButton } from "../../components/Common/FormSubmitButton";
import { FormTitle } from "../../components/Common/FormTitle";
import { FormWrapper } from "../../components/Common/FormWrapper";
import { Spinner } from "../../components/Spinner";
import { groupTypeOptions } from "../../constants/options";
import { GroupType } from "../../interfaces/types";
import {
  useAdminGroupUpdateFormData,
  useAdminCreateGroup,
  useAdminUpdateGroup,
} from "../../queries/admin";
import { findGroupById } from "../../utils/group";
import { GroupSelect } from "./GroupSelect";
import GroupSchema from "./GroupsSchema";
import { Table } from "../../components/Common/Table";
import { Toggle } from "../../components/Common/Toggle";
import { Divider } from "../../components/Common/Divider";
import { FormInfoText } from "../../components/Common/FormInfoText";
import * as uuid from "uuid";
import { groupHierarchiesSelector } from "../../selectors/groups";

const DeviceTableWrapper = styled.div`
  margin: 0 6.25% 1rem;
`;

type User = {
  id: number;
  name: string;
  deviceChangeEmailsEnabled: boolean;
};
type DeviceChangeSmartUserTableProps = {
  users: User[];
  selectedUserIds: number[];
  updateSelectedUserIds: (userIds: number[]) => void;
};

function DeviceChangeSmartUserTable(props: DeviceChangeSmartUserTableProps) {
  const { users, selectedUserIds, updateSelectedUserIds } = props;

  const tableData = useMemo(() => {
    return users.map((user) => ({
      ...user,
      deviceChangeEmailsEnabled: selectedUserIds.includes(user.id),
    }));
  }, [selectedUserIds, users]);

  const toggleUserSelection = useCallback(
    (userId: number) =>
      updateSelectedUserIds(
        selectedUserIds.includes(userId)
          ? selectedUserIds.filter((id) => id !== userId)
          : [...selectedUserIds, userId]
      ),
    [updateSelectedUserIds, selectedUserIds]
  );

  const tableColumns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "name",
        width: 3,
      },
      {
        Header: "Send emails",
        Cell: ({ row }: any) => (
          <Toggle
            selected={row.original.deviceChangeEmailsEnabled}
            onToggle={() => {
              toggleUserSelection(row.original.id);
            }}
          />
        ),
        width: 1,
        id: "deviceChangeEmailsEnabled",
      },
    ],
    [toggleUserSelection]
  );

  if (users.length === 0) {
    return (
      <FormValue>
        This group has no users. Add users to configure their email settings
        here.
      </FormValue>
    );
  }

  return (
    <Table
      data={tableData}
      infiniteScroll
      sortable
      initialState={{ sortBy: [{ id: "name" }] }}
      columns={tableColumns}
    />
  );
}

const FormRectangle = styled(FormRectangleRaw)`
  width: 30rem;

  #externalDeviceChangeEmailRecipients {
    height: unset;
    padding: 0.7rem;
  }
`;

const generatePassword = () => {
  return uuid.v4();
};

enum PasswordState {
  NOT_SET = 0,
  ALREADY_SET = 1,
  UPDATED_IN_FORM = 2,
}

type FormValues = {
  name: string;
  parentId: number;
  integrationUsername: string;
  type: GroupType;
  group_email: string;
  serviceRequestEmail: string;
  useServiceSystemPartnerIntegration: boolean;
  deviceChangeEmailsEnabled: boolean;
  usePressureCurveFillLevel: boolean;
  smartDeviceChangeEmailRecipientIds: number[];
  externalDeviceChangeEmailRecipients: string[];
  passwordState: PasswordState.NOT_SET | PasswordState.ALREADY_SET;
  supportRequestEmail: string;
};
type PasswordFields =
  | {
      passwordState: PasswordState.NOT_SET;
      password: undefined;
    }
  | { passwordState: PasswordState.ALREADY_SET; password: undefined }
  | { passwordState: PasswordState.UPDATED_IN_FORM; password: string };
type FormSubmitValues = Omit<FormValues, "passwordState"> & PasswordFields;

type InternalFormValues = Omit<
  FormValues,
  "externalDeviceChangeEmailRecipients" | "passwordState"
> & { externalDeviceChangeEmailRecipients: string } & PasswordFields;

type GroupFormProps = {
  initialValues: FormValues;
  groupId: number | null;
  title: string;
  groupUsers: User[];
  isSubmitting: boolean;
  isSubmitted: boolean;
  onSubmit: (values: FormSubmitValues) => void;
};

function GroupForm(props: GroupFormProps) {
  const groupHierarchy = useSelector(groupHierarchiesSelector);

  const [confirmParentChangeOpen, setConfirmParentChangeOpen] = useState(false);
  const [parentChangeConfirmed, setParentChangeConfirmed] = useState(false);

  const initialValues: InternalFormValues = {
    ...props.initialValues,
    externalDeviceChangeEmailRecipients: props.initialValues.externalDeviceChangeEmailRecipients.join(
      "\n"
    ),
    password: undefined,
  };

  const confirmParentChange = (initialParentId: number, parentId: number) => {
    if (initialParentId === parentId) {
      return true;
    } else if (parentChangeConfirmed) {
      setParentChangeConfirmed(false);
      return true;
    }

    setConfirmParentChangeOpen(true);
    return false;
  };

  return (
    <FormBackground cursor={props.isSubmitting ? "wait" : undefined}>
      <FormRectangle>
        <FormTitle title={props.title} />
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            if (
              !confirmParentChange(
                props.initialValues.parentId,
                values.parentId
              ) &&
              !props.isSubmitted
            ) {
              actions.setSubmitting(false);
              return;
            }

            props.onSubmit({
              ...values,
              externalDeviceChangeEmailRecipients: values.externalDeviceChangeEmailRecipients
                .split("\n")
                .filter(Boolean)
                .map((str) => str.trim()),
            });
          }}
          validationSchema={GroupSchema}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            submitForm,
          }) => (
            <>
              {confirmParentChangeOpen && (
                <ConfirmationModal
                  isOpen={confirmParentChangeOpen}
                  onConfirm={(didConfirm) => {
                    setConfirmParentChangeOpen(false);
                    if (didConfirm) {
                      setParentChangeConfirmed(true);
                      submitForm();
                    }
                  }}
                  message={`Are you sure you want to change the parent group of
                    the group from "${
                      findGroupById(
                        groupHierarchy,
                        props.initialValues.parentId
                      )?.name
                    }"
                    to "${
                      findGroupById(groupHierarchy, values.parentId)?.name
                    }"?`}
                />
              )}
              <FormWrapper>
                <FormInput
                  id="name"
                  type="text"
                  label="Name"
                  error={touched.name ? errors.name : undefined}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                />
                <GroupSelect
                  id="parentId"
                  label="Parent group"
                  value={values.parentId}
                  onChange={setFieldValue}
                  error={errors.parentId}
                  options={groupHierarchy}
                  disabledGroup={(group) =>
                    props.groupId != null && group.groupId === props.groupId
                  }
                  required={true}
                />
                <FormInput
                  id="type"
                  type="select"
                  label="Type"
                  component="select"
                  options={groupTypeOptions}
                  error={touched.type ? errors.type : undefined}
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                />
                <FormInput
                  id="group_email"
                  type="text"
                  label="Group email"
                  labelInfoTooltip="Email for receiving new empty orders"
                  whiteInfoTooltip
                  error={touched.group_email ? errors.group_email : undefined}
                  value={values.group_email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="serviceRequestEmail"
                  type="text"
                  label="Service request email"
                  labelInfoTooltip="Email for receiving new service orders"
                  whiteInfoTooltip
                  error={
                    touched.serviceRequestEmail
                      ? errors.serviceRequestEmail
                      : undefined
                  }
                  value={values.serviceRequestEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="supportRequestEmail"
                  type="text"
                  label="Support request email"
                  labelInfoTooltip="Email for receiving support and change requests created in SMARTwaste"
                  whiteInfoTooltip
                  error={
                    touched.supportRequestEmail
                      ? errors.supportRequestEmail
                      : undefined
                  }
                  value={values.supportRequestEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormToggle
                  id="useServiceSystemPartnerIntegration"
                  label="Tracking cloud integration"
                  onChange={setFieldValue}
                  selected={values.useServiceSystemPartnerIntegration}
                />
                <FormToggle
                  id="deviceChangeEmailsEnabled"
                  label="Device change emails"
                  labelInfoTooltip="Emails about changes in the devices operated or owned by this group"
                  whiteInfoTooltip
                  onChange={setFieldValue}
                  selected={values.deviceChangeEmailsEnabled}
                />
                {values.deviceChangeEmailsEnabled && (
                  <>
                    <FormInput
                      id="externalDeviceChangeEmailRecipients"
                      type=""
                      component="textarea"
                      componentProps={{ rows: 5 }}
                      label="External device change email recipients"
                      value={values.externalDeviceChangeEmailRecipients}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.externalDeviceChangeEmailRecipients
                          ? errors.externalDeviceChangeEmailRecipients
                          : undefined
                      }
                    />
                    <Divider />
                    <FormInfoText>
                      Configure SMART device change email recipients
                    </FormInfoText>
                    <DeviceTableWrapper>
                      <DeviceChangeSmartUserTable
                        users={props.groupUsers}
                        selectedUserIds={
                          values.smartDeviceChangeEmailRecipientIds
                        }
                        updateSelectedUserIds={(selectedIds) =>
                          setFieldValue(
                            "smartDeviceChangeEmailRecipientIds",
                            selectedIds
                          )
                        }
                      />
                    </DeviceTableWrapper>
                    <Divider />
                  </>
                )}
                <FormToggle
                  id="usePressureCurveFillLevel"
                  label="Enhanced fill level accuracy for supported devices"
                  labelInfoTooltip="When changed, all devices in this group and subgroups will get the changed setting. This setting is available for per device in device settings."
                  whiteInfoTooltip
                  onChange={setFieldValue}
                  selected={values.usePressureCurveFillLevel}
                />
                <FormInput
                  id="integrationUsername"
                  type="text"
                  label="Integration API username"
                  error={
                    touched.integrationUsername
                      ? errors.integrationUsername
                      : undefined
                  }
                  value={values.integrationUsername}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputBox>
                  <FormLabel>Integration API password</FormLabel>
                  <FormValue>
                    {(() => {
                      switch (values.passwordState) {
                        case PasswordState.NOT_SET:
                          return "Not set";
                        case PasswordState.ALREADY_SET:
                          return "*".repeat(12);
                        case PasswordState.UPDATED_IN_FORM:
                          return values.password;
                      }
                    })()}
                  </FormValue>
                </InputBox>
                <RoundDenimButton
                  disabled={props.isSubmitting}
                  type="button"
                  margin="0 0 0 12.5%"
                  onClick={() => {
                    setFieldValue("password", generatePassword());
                    setFieldValue(
                      "passwordState",
                      PasswordState.UPDATED_IN_FORM
                    );
                  }}
                >
                  {values.passwordState === PasswordState.NOT_SET
                    ? "Create new password"
                    : "Recreate password"}
                </RoundDenimButton>
                <FlexBoxWrapper marginTop={20}>
                  <Link to={`/admin/groups`}>
                    <FormSubmitButton
                      disabled={props.isSubmitting}
                      cursor={props.isSubmitting ? "wait" : undefined}
                    >
                      Back
                    </FormSubmitButton>
                  </Link>
                  &nbsp;
                  {!props.isSubmitted || props.isSubmitting ? (
                    <FormSubmitButton
                      type="submit"
                      disabled={props.isSubmitting}
                      cursor={props.isSubmitting ? "wait" : undefined}
                    >
                      Save
                    </FormSubmitButton>
                  ) : (
                    <Link to={`/admin/licenses/groups/${props.groupId}`}>
                      <FormSubmitButton type="button">
                        Edit group licenses
                      </FormSubmitButton>
                    </Link>
                  )}
                </FlexBoxWrapper>
              </FormWrapper>
            </>
          )}
        </Formik>
      </FormRectangle>
    </FormBackground>
  );
}

export function GroupCreateForm(props: RouteComponentProps) {
  const createGroup = useAdminCreateGroup();

  return (
    <GroupForm
      groupId={createGroup.isSuccess ? createGroup.data.id : null}
      groupUsers={[]}
      initialValues={{
        name: "",
        parentId: props.location.state && props.location.state.parentId,
        integrationUsername: "",
        type: GroupType.REGULAR,
        group_email: "",
        serviceRequestEmail: "",
        useServiceSystemPartnerIntegration: false,
        deviceChangeEmailsEnabled: false,
        usePressureCurveFillLevel: false,
        smartDeviceChangeEmailRecipientIds: [],
        externalDeviceChangeEmailRecipients: [],
        supportRequestEmail: "",
        passwordState: PasswordState.NOT_SET,
      }}
      onSubmit={(values) =>
        createGroup.mutate({
          group: {
            ...values,
            ...(values.passwordState === PasswordState.UPDATED_IN_FORM
              ? { password: values.password }
              : {}),
          },
        })
      }
      title="Create group"
      isSubmitting={createGroup.isLoading}
      isSubmitted={createGroup.isSuccess}
    />
  );
}

export function GroupUpdateForm(props: RouteComponentProps<{ id: string }>) {
  const groupId = Number(props.match.params.id);
  const group = useAdminGroupUpdateFormData(groupId);
  const updateGroup = useAdminUpdateGroup();

  if (group.isFetching) {
    return <Spinner />;
  } else if (
    group.data &&
    group.data.parentId != null &&
    group.isFetchedAfterMount
  ) {
    return (
      <GroupForm
        groupId={groupId}
        groupUsers={group.data.users}
        initialValues={{
          ...group.data,
          integrationUsername: group.data.integrationUsername ?? "",
          group_email: group.data.group_email ?? "",
          serviceRequestEmail: group.data.serviceRequestEmail ?? "",
          type: group.data.type ?? GroupType.REGULAR,
          parentId: group.data.parentId,
          smartDeviceChangeEmailRecipientIds: group.data.users
            .filter((user) => user.deviceChangeEmailsEnabled)
            .map((user) => user.id),
          supportRequestEmail: group.data.supportRequestEmail ?? "",
          usePressureCurveFillLevel: group.data.usePressureCurveFillLevel,
          passwordState: group.data.passwordSet
            ? PasswordState.ALREADY_SET
            : PasswordState.NOT_SET,
        }}
        onSubmit={(values) =>
          updateGroup.mutate({
            groupId,
            group: {
              ...values,
              ...(values.passwordState === PasswordState.UPDATED_IN_FORM
                ? { password: values.password }
                : {}),
            },
          })
        }
        title="Edit group"
        isSubmitting={updateGroup.isLoading}
        isSubmitted={updateGroup.isSuccess}
      />
    );
  }

  return <div>An error occurred</div>;
}
