import { Formik } from "formik";
import styled from "styled-components";

import { FormWrapper } from "../../../components/Common/FormWrapper";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import { getSurveyCategoryNameById } from "../../../utils/utils";
import { SurveyIntervalInput } from "./SurveyIntervalInput";
import { SurveyType } from "@shared/types";

type SurveyFormProps = {
  onSubmit: (values: any) => void;
  onDelete?: (values: any) => void;
  onCancel: () => void;
  initialValues?: any;
  surveyGlobalSettings: any;
};

const SurveyFormButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;
const SurveyFormField = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  border-bottom: 1px solid #ccc;
`;
const SurveyFormLabel = styled.label`
  display: block;
`;
const SurveyFormInput = styled.input`
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 6px 4px;
`;
const SurveyFormCheckbox = styled.input`
  width: 20px;
  height: 20px;
`;
const SurveyFormSelect = styled.select`
  border-radius: 8px;
  border: 1px solid #ccc;
  background: white;
  padding: 6px 4px;
  width: 200px;
`;
const SurveyFormInfo = styled.p`
  font-size: 0.8em;
`;
const SurveyFormTextArea = styled.textarea`
  font-size: 0.8em;
`;

export const SurveyForm = (props: SurveyFormProps) => {
  const newSurvey = props.initialValues === undefined;
  const handleSubmit = (values: any) => {
    props.onSubmit(values);
  };

  return (
    <Formik
      initialValues={
        props.initialValues
          ? props.initialValues
          : {
              key: "",
              name: "",
              active: true,
              surveyType: SurveyType.SURVEY_ACTION_BASED,
              triggerAfter: "180 days",
              triggerPeriod: "30 days",
              repeatInterval: "180 days",
              dismissalThreshold: 3,
              dismissalCooldown: "30 days",
              repeatAfterDismissal: false,
              popupDelay: 10,
              popupTitle: "Help up improve SMART 🙏",
              popupText:
                "Would you like to help us improve our service by answering a few questions?",
            }
      }
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const { values, handleChange, setFieldValue } = formikProps;
        return (
          <FormWrapper>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="name">Name</SurveyFormLabel>
              <SurveyFormInput
                id={"name"}
                type={"text"}
                onChange={(e) => setFieldValue("name", e.target.value)}
                value={values.name}
              />
              <SurveyFormInfo>
                Internal survey name, only visible to admins
              </SurveyFormInfo>
            </SurveyFormField>
            <SurveyFormField>
              {newSurvey ? (
                <>
                  <SurveyFormLabel htmlFor="surveyType">
                    Survey type
                  </SurveyFormLabel>
                  <SurveyFormSelect
                    id={"surveyType"}
                    onChange={handleChange}
                    value={values.surveyType}
                  >
                    <option value={SurveyType.SURVEY_ACTION_BASED}>
                      Action-based survey
                    </option>
                  </SurveyFormSelect>
                </>
              ) : (
                <div>
                  Type:{" "}
                  {getSurveyCategoryNameById(props.initialValues?.surveyType)}
                </div>
              )}
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="key">Zef.fi key</SurveyFormLabel>
              <SurveyFormInput
                id={"key"}
                type={"text"}
                onChange={handleChange}
                value={values.key}
              />
              <SurveyFormInfo>Zef.fi embed key for the survey</SurveyFormInfo>
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="active">Active</SurveyFormLabel>
              <SurveyFormCheckbox
                id={"active"}
                type={"checkbox"}
                onChange={handleChange}
                checked={values.active}
              />
              <SurveyFormInfo>
                Enable or disable this survey. Disabled surveys will not be
                shown to users, even if they have already been queued.
              </SurveyFormInfo>
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="triggerAfter">
                Trigger after
              </SurveyFormLabel>
              <SurveyIntervalInput
                id="triggerAfter"
                onChange={(value: string) =>
                  setFieldValue("triggerAfter", value)
                }
                value={values["triggerAfter"]}
              />
              <SurveyFormInfo>
                Time in days or sessions after which the survey can be added to
                a user's queue.
                <br />
                For repeating surveys, the Repeat Interval setting will be used
                for subsequent triggers.
                <br />
                Current setting: The survey will be queued{" "}
                {values["triggerAfter"]} after the user's first login.
              </SurveyFormInfo>
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="triggerPeriod">
                Trigger period
              </SurveyFormLabel>
              <SurveyIntervalInput
                id="triggerPeriod"
                onChange={(value: string) =>
                  setFieldValue("triggerPeriod", value)
                }
                value={values["triggerPeriod"]}
              />
              <SurveyFormInfo>
                Maximum time a survey will stay in a user's queue before it is
                automatically removed from the queue.
                <br />
                Current setting: The survey will expire{" "}
                {values["triggerPeriod"]} after it was placed in the queue.
              </SurveyFormInfo>
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="repeatInterval">
                Repeat interval
              </SurveyFormLabel>
              <SurveyIntervalInput
                id="repeatInterval"
                onChange={(value: string) =>
                  setFieldValue("repeatInterval", value)
                }
                value={values["repeatInterval"]}
              />
              <SurveyFormInfo>
                How often the survey should repeat after it has been completed
                or dismissed.
                <br />
                Current setting: The survey will repeat every{" "}
                {values["repeatInterval"]}.
              </SurveyFormInfo>
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="dismissalThreshold">
                Dismissal threshold
              </SurveyFormLabel>
              <SurveyFormInput
                id="dismissalThreshold"
                type="number"
                onChange={handleChange}
                value={values.dismissalThreshold}
              />
              <SurveyFormInfo>
                Number of times the survey can be dismissed before it is removed
                from the queue, and the user should be placed on Dismissal
                Cooldown.
              </SurveyFormInfo>
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="repeatAfterDismissal">
                Repeat after dismissal
              </SurveyFormLabel>
              <SurveyFormSelect
                id={"repeatAfterDismissal"}
                onChange={(e) =>
                  setFieldValue(
                    "repeatAfterDismissal",
                    e.target.value === "true"
                  )
                }
                value={values.repeatAfterDismissal?.toString()}
                // Options can't be boolean values, apparently
              >
                <option value={"false"}>Don't repeat</option>
                <option value={"true"}>Repeat once</option>
              </SurveyFormSelect>
              <SurveyFormInfo>
                If the survey should repeat after the dismissal cooldown period.
                <br />
                <em>Don't repeat</em> means the survey will immediately be
                removed from the queue once the dismissal threshold has been
                reached.
                <br />
                <em>Repeat once</em> means that after the survey has been
                dismissed {values["dismissalThreshold"]} times, it will be shown
                again <em>once</em> after the dismissal cooldown period ends.
                <br />
              </SurveyFormInfo>
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="dismissalCooldown">
                Dismissal cooldown
              </SurveyFormLabel>
              <SurveyIntervalInput
                id="dismissalCooldown"
                onChange={(value: string) =>
                  setFieldValue("dismissalCooldown", value)
                }
                value={values["dismissalCooldown"]}
              />
              <SurveyFormInfo>
                How long the user should not be shown the survey after it has
                been dismissed.
                <br />
                {values["repeatAfterDismissal"] ? (
                  <>
                    Current setting: The survey will be shown after{" "}
                    {values["dismissalCooldown"]} if it has been dismissed{" "}
                    {values["dismissalThreshold"]} times.
                  </>
                ) : (
                  <>
                    <strong>Note: This setting has no effect</strong>, because
                    the survey is set to not repeat after dismissal.
                  </>
                )}
              </SurveyFormInfo>
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="triggerDelay">
                Trigger delay
              </SurveyFormLabel>
              <SurveyFormInput
                id="popupDelay"
                type="number"
                onChange={handleChange}
                value={values.popupDelay}
              />
              <SurveyFormInfo>
                Time (in seconds) before the pre-survey popup should appear
                after the page has loaded.
              </SurveyFormInfo>
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="popupTitleEn">
                Pre-survey popup title (English)
              </SurveyFormLabel>
              <SurveyFormInput
                id="popupTitleEn"
                onChange={handleChange}
                value={values.popupTitleEn}
                size={40}
              />
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="popupTitleFi">
                Pre-survey popup title (Finnish)
              </SurveyFormLabel>
              <SurveyFormInput
                id="popupTitleFi"
                onChange={handleChange}
                value={values.popupTitleFi}
                size={40}
              />
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="popupTitlePl">
                Pre-survey popup title (Polish)
              </SurveyFormLabel>
              <SurveyFormInput
                id="popupTitlePl"
                onChange={handleChange}
                value={values.popupTitlePl}
                size={40}
              />
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="popupTextEn">
                Pre-survey popup text (English)
              </SurveyFormLabel>
              <SurveyFormTextArea
                id="popupTextEn"
                onChange={handleChange}
                value={values.popupTextEn}
                rows={4}
                cols={50}
              />
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="popupTextFi">
                Pre-survey popup text (Finnish)
              </SurveyFormLabel>
              <SurveyFormTextArea
                id="popupTextFi"
                onChange={handleChange}
                value={values.popupTextFi}
                rows={4}
                cols={50}
              />
            </SurveyFormField>
            <SurveyFormField>
              <SurveyFormLabel htmlFor="popupTextPl">
                Pre-survey popup text (Polish)
              </SurveyFormLabel>
              <SurveyFormTextArea
                id="popupTextPl"
                onChange={handleChange}
                value={values.popupTextPl}
                rows={4}
                cols={50}
              />
            </SurveyFormField>
            <SurveyFormButtons>
              <FormSubmitButton onClick={props.onCancel}>
                Cancel
              </FormSubmitButton>
              <FormSubmitButton
                type="submit"
                style={{ background: "var(--seafoam-blue)" }}
              >
                Save
              </FormSubmitButton>
              {!newSurvey && props.onDelete && (
                <>
                  <FormSubmitButton
                    onClick={() =>
                      props.onDelete && props.onDelete(props.initialValues.id)
                    }
                    style={{
                      right: "10px",
                      position: "absolute", // Force the delete button off to the side
                      background: "#fc4444",
                    }}
                  >
                    Delete
                  </FormSubmitButton>
                </>
              )}
            </SurveyFormButtons>
          </FormWrapper>
        );
      }}
    </Formik>
  );
};
