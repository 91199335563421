export function getHoursAtTimeZone(timeZone: string): number {
  const hoursString = new Date().toLocaleString("en-US", {
    hour: "2-digit",
    hour12: false,
    timeZone,
  });
  const hours = parseInt(hoursString, 10);

  if (isNaN(hours)) {
    throw new Error(
      `time.ts: Unable to obtain hours for time zone ${timeZone}`
    );
  }
  return hours;
}

export function getWeekdayAtTimeZone(timeZone: string): number {
  const weekdayStringToNumber = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  const weekdayString = new Date().toLocaleString("en-US", {
    weekday: "long",
    timeZone,
  }) as keyof typeof weekdayStringToNumber;

  const weekday = weekdayStringToNumber[weekdayString];

  if (weekday === undefined) {
    throw new Error(
      `time.ts: Unable to obtain weekday for time zone ${timeZone}`
    );
  }
  return weekday;
}

export function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
