import { call, all, put, actionChannel, take, fork } from "redux-saga/effects";
import * as deviceApi from "../api/devices";
import * as TYPES from "../constants/actionTypes";
import { maximumDevicesPageSize } from "@shared/constants";
import { NotifyError, NotifySuccess } from "../components/Common/Notify";
import { push, replace } from "connected-react-router";
import _ from "lodash";

/**
 * Fetch devices action. IF fetch fails puts action FETCH_DEVICES_FAILURE. For success passes data
 * through FETCH_DEVICES_SUCCESS action.
 * @export
 * @param {*} action
 */
export function* fetchDevices(action: any) {
  try {
    const firstPageResult = yield call(deviceApi.getDevices, action, {
      offset: 0,
      limit: maximumDevicesPageSize,
    });

    const { devices = [], totalDevices = 0 } = firstPageResult.data;
    if (devices.length < totalDevices) {
      const pageDevices = yield call(
        getAdditionalDevicePages,
        action,
        totalDevices,
        maximumDevicesPageSize
      );

      devices.push(...pageDevices);
    }

    // Ensure that there are no duplicates, if by chance a new device is added
    // to the db while paginating
    const devicesNoDuplicates = _.uniqBy(
      devices,
      (dev: any) => dev.serialNumber
    );

    yield put({
      type: TYPES.FETCH_DEVICES_SUCCESS,
      payload: { devices: devicesNoDuplicates },
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_DEVICES_FAILURE });
  }
}

export function* fetchAllHGDevices(action: any) {
  try {
    const response = yield call(deviceApi.getAllHGDevices, action);
    yield put({
      type: TYPES.GET_ALL_HG_DEVICES_SUCCESS,
      payload: {
        devices: response.data,
      },
    });
  } catch (error) {
    yield put({ type: TYPES.GET_ALL_HG_DEVICES_FAILURE });
    NotifyError(TYPES.GET_ALL_HG_DEVICES_FAILURE);
  }
}

/**
 * Sets H&G device data
 *
 * @export
 * @param {*} action
 */
export function* patchHGDevice(action: any) {
  try {
    const result = yield call(deviceApi.patchHGDevice, action);
    yield put({
      type: TYPES.PATCH_HG_DEVICE_SUCCESS,
      payload: { ...result.data },
    });
    NotifySuccess("DEVICE_UPDATE_SUCCESS");
    yield put(push("/admin/devices/device-management"));
  } catch (err) {
    yield put({ type: TYPES.PATCH_HG_DEVICE_FAILURE });
    NotifyError(TYPES.PATCH_HG_DEVICE_FAILURE);
  }
}

export function* fetchAllRetrofitLightDevices(action: any) {
  try {
    const response = yield call(deviceApi.getAllRetrofitLightDevices, action);
    yield put({
      type: TYPES.GET_ALL_RETROFIT_LIGHT_DEVICES_SUCCESS,
      payload: {
        devices: response.data,
      },
    });
  } catch (error) {
    yield put({ type: TYPES.GET_ALL_RETROFIT_LIGHT_DEVICES_FAILURE });
    NotifyError(TYPES.GET_ALL_RETROFIT_LIGHT_DEVICES_FAILURE);
  }
}

export function* patchRetrofitLightDevice(action: any) {
  try {
    const result = yield call(deviceApi.patchRetrofitLightDevice, action);
    yield put({
      type: TYPES.PATCH_RETROFIT_LIGHT_DEVICE_SUCCESS,
      payload: { ...result.data },
    });
    NotifySuccess("DEVICE_UPDATE_SUCCESS");
    yield put(push("/admin/devices/device-management"));
  } catch (err) {
    yield put({ type: TYPES.PATCH_RETROFIT_LIGHT_DEVICE_FAILURE });
    NotifyError(TYPES.PATCH_RETROFIT_LIGHT_DEVICE_FAILURE);
  }
}

export function* fetchAllIProtoxiDevices(action: any) {
  try {
    const response = yield call(deviceApi.getAllIProtoxiDevices, action);
    yield put({
      type: TYPES.GET_ALL_IPROTOXI_DEVICES_SUCCESS,
      payload: {
        devices: response.data,
      },
    });
  } catch (error) {
    yield put({ type: TYPES.GET_ALL_IPROTOXI_DEVICES_FAILURE });
    NotifyError(TYPES.GET_ALL_IPROTOXI_DEVICES_FAILURE);
  }
}

export function* patchIProtoxiDevice(action: any) {
  try {
    const result = yield call(deviceApi.patchIProtoxiDevice, action);
    yield put({
      type: TYPES.PATCH_IPROTOXI_DEVICE_SUCCESS,
      payload: { ...result.data },
    });
    NotifySuccess("DEVICE_UPDATE_SUCCESS");
    yield put(push("/admin/devices/device-management"));
  } catch (err) {
    yield put({ type: TYPES.PATCH_IPROTOXI_DEVICE_FAILURE });
    NotifyError(TYPES.PATCH_IPROTOXI_DEVICE_FAILURE);
  }
}

function* getAdditionalDevicePages(
  action: any,
  totalDevices: number,
  pageSize: number
) {
  const pageOffsets = _.range(pageSize, totalDevices, pageSize);

  const pageResults = yield all(
    pageOffsets.map((offset) =>
      call(deviceApi.getDevices, action, {
        offset,
        limit: pageSize,
      })
    )
  );

  return pageResults.map((result: any) => result.data.devices).flat();
}
/**
 * Implements a channel watcher for FETCH_DEVICES which forks
 * for a new asynchronious call for every FETCH_DEVICES task.
 */
export function* watchFetchDevicesRequests() {
  const requestChan = yield actionChannel(TYPES.FETCH_DEVICES);
  while (true) {
    const action = yield take(requestChan);
    yield fork(fetchDevices, action);
  }
}
export function* fetchDevicesRoutes(action: any) {
  try {
    const result = yield call(deviceApi.getDevicesRoutes, action);
    yield put({
      type: TYPES.FETCH_DEVICES_ROUTES_SUCCESS,
      payload: { ...result.data },
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_DEVICES_ROUTES_FAILURE });
  }
}

/**
 * Sets devices details e.x asset name
 *
 * @export
 * @param {*} action
 */
export function* patchDeviceDetails(action: any) {
  try {
    const result = yield call(deviceApi.postDeviceDetail, action);
    yield put({
      type: TYPES.PATCH_DEVICE_DETAIL_SUCCESS,
      payload: { ...result.data },
    });
    NotifySuccess(TYPES.PATCH_DEVICE_DETAIL_SUCCESS);
  } catch (err) {
    yield put({ type: TYPES.PATCH_DEVICE_DETAIL_FAILURE });
  }
}

export function* resetBaleCounter(action: any) {
  try {
    // Bale counter values are updated optimitically by actionTypes.RESET_BALE_COUNTER
    yield call(deviceApi.postBaleCounterReset, action);
  } catch (e) {
    NotifyError("DEFAULT_ERROR");
    yield put({
      type: TYPES.RESET_BALE_COUNTER_FAILURE,
      payload: action.payload,
    });
  }
}

export function* updateBalesReady(action: any) {
  try {
    // Bales ready value is updated optimistically by actionTypes.CHANGE_BALES_READY
    yield call(deviceApi.postBaleCounterBalesReady, action);
  } catch (err) {
    NotifyError("DEFAULT_ERROR");
    yield put({
      type: TYPES.CHANGE_BALES_READY_FAILURE,
      payload: action.payload,
    });
  }
}

/**
 * Get device history
 *
 * @export
 * @param {*} action
 */
export function* fetchDeviceHistory(action: any) {
  try {
    const result = yield call(deviceApi.getDeviceHistory, action);

    yield put({
      type: TYPES.GET_DEVICE_HISTORY_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.GET_DEVICE_HISTORY_FAILURE });
  }
}

export function* updateOwnerGroupForDevices(action: any) {
  try {
    const result = yield call(deviceApi.updateOwnerGroup, action);
    yield put({
      type: TYPES.UPDATE_OWNER_GROUP_FOR_DEVICES_SUCCESS,
      payload: result.data,
    });
    NotifySuccess(TYPES.UPDATE_OWNER_GROUP_FOR_DEVICES_SUCCESS);
  } catch (err) {
    yield put({
      type: TYPES.UPDATE_OWNER_GROUP_FOR_DEVICES_FAILURE,
    });
    NotifyError(TYPES.UPDATE_OWNER_GROUP_FOR_DEVICES_FAILURE);
  }
}

export function* updateOperatorGroupForDevices(action: any) {
  try {
    const result = yield call(deviceApi.updateOperatorGroup, action);
    yield put({
      type: TYPES.UPDATE_OPERATOR_GROUP_FOR_DEVICES_SUCCESS,
      payload: result.data,
    });
    NotifySuccess(TYPES.UPDATE_OPERATOR_GROUP_FOR_DEVICES_SUCCESS);
  } catch (err) {
    yield put({
      type: TYPES.UPDATE_OPERATOR_GROUP_FOR_DEVICES_FAILURE,
    });
    NotifyError(TYPES.UPDATE_OPERATOR_GROUP_FOR_DEVICES_FAILURE);
  }
}

export function* updateActiveStateForDevices(action: any) {
  try {
    const result = yield call(deviceApi.updateActiveState, action);
    yield put({
      type: TYPES.UPDATE_ACTIVE_STATE_FOR_DEVICES_SUCCESS,
      payload: result.data,
    });
    NotifySuccess(TYPES.UPDATE_ACTIVE_STATE_FOR_DEVICES_SUCCESS);
  } catch (err) {
    yield put({
      type: TYPES.UPDATE_ACTIVE_STATE_FOR_DEVICES_FAILURE,
    });
    NotifyError(TYPES.UPDATE_ACTIVE_STATE_FOR_DEVICES_FAILURE);
  }
}

export function* fetchAllDeviceTasks(action: any) {
  try {
    const result = yield call(deviceApi.getAllDeviceTasks, action);
    yield put({
      type: TYPES.GET_ALL_DEVICE_TASKS_SUCCESS,
      payload: { tasks: result.data },
    });
  } catch (err) {
    yield put({ type: TYPES.GET_ALL_DEVICE_TASKS_FAILURE });
    NotifyError(TYPES.GET_ALL_DEVICE_TASKS_FAILURE);
  }
}

export function* createDeviceTasks(action: any) {
  try {
    yield call(deviceApi.postDeviceTasks, action);
    NotifySuccess(TYPES.CREATE_DEVICE_TASKS_SUCCESS);
    yield put(replace("/admin/devices/tasks/fill-level"));
  } catch (err) {
    NotifyError(TYPES.CREATE_DEVICE_TASKS_FAILURE, err?.response?.data?.error);
  }
}

export function* updateDeviceTask(action: any) {
  try {
    yield call(deviceApi.patchDeviceTasks, action);
    NotifySuccess(TYPES.UPDATE_DEVICE_TASK_SUCCESS);
  } catch (err) {
    NotifyError(TYPES.UPDATE_DEVICE_TASK_FAILURE);
  }
}

export function* deleteDeviceTask(action: any) {
  try {
    yield call(deviceApi.deleteDeviceTask, action);
    yield put({
      type: TYPES.DELETE_DEVICE_TASK_SUCCESS,
      payload: action.payload,
    });
    NotifySuccess(TYPES.DELETE_DEVICE_TASK_SUCCESS);
  } catch (err) {
    NotifyError(TYPES.DELETE_DEVICE_TASK_FAILURE);
  }
}

export function* fetchPressureData(action: any) {
  try {
    const res = yield call(deviceApi.getPressureData, action);
    yield put({
      type: TYPES.GET_PRESSURE_DATA_SUCCESS,
      payload: {
        pressureData: res.data,
        serialNumber: action.payload.serialNumber,
      },
    });
  } catch (err) {
    yield put({
      type: TYPES.GET_PRESSURE_DATA_FAILURE,
      payload: action.payload,
    });
    NotifyError();
  }
}

export function* fetchDeviceFillLevels(action: any) {
  try {
    const res = yield call(deviceApi.getDeviceFillLevels, action);
    yield put({
      type: TYPES.GET_DEVICE_FILL_LEVELS_SUCCESS,
      payload: {
        fillLevels: res.data,
        serialNumber: action.payload.serialNumber,
      },
    });
  } catch (err) {
    yield put({
      type: TYPES.GET_DEVICE_FILL_LEVELS_FAILURE,
      payload: action.payload,
    });
    NotifyError();
  }
}
