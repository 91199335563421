import {
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER,
  SKIP_COLUMNS_SELECTED,
  SKIP_COLUMNS_SELECTED_SUCCESS,
  SKIP_COLUMNS_SELECTED_FAILURE,
  DEVICES_COLUMNS_SELECTED,
  DEVICES_COLUMNS_SELECTED_SUCCESS,
  DEVICES_COLUMNS_SELECTED_FAILURE,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_LANGUAGE_FAILURE,
  DO_SERVICE_TOOL_LOGIN,
  DO_SERVICE_TOOL_LOGIN_SUCCESS,
  DO_SERVICE_TOOL_LOGIN_FAILURE,
  ENABLE_MFA_FOR_USER_SUCCESS,
  DISABLE_MFA_FOR_USER_SUCCESS,
  USER_SURVEY_ACTION_SUCCESS,
} from "../constants/actionTypes";
import * as selectableColumnUtils from "../utils/selectableColumns";
import * as selectableColumnConstants from "../constants/selectableColumns";
import { SelectableColumns, UserRole } from "../interfaces/types";
import { SurveyActions } from "@shared/types";

export type UsersState = {
  userName: string;
  role: -1 | UserRole;
  groups: string[];
  primaryAnalyticsGroup: { id: number; guid: string } | undefined;
  admin: boolean | undefined;
  scaleAdmin: boolean | undefined;
  dataAnalyticsAllowed: boolean;
  language: undefined | string;
  deviceColumnsSelected: SelectableColumns[];
  skipColumnsSelected: SelectableColumns[];
  licenses:
    | undefined
    | { intro: boolean; insights: boolean; integrated: boolean };
  serviceToolLoginOngoing: boolean;
  mfaEnabled: boolean;
  survey:
    | {
        key: string;
        settings: {
          [key: string]: string | number | boolean;
        };
        opened?: boolean;
      }
    | undefined;
};

const initialState: UsersState = {
  userName: "",
  role: -1,
  groups: [],
  primaryAnalyticsGroup: undefined,
  admin: undefined,
  scaleAdmin: undefined,
  dataAnalyticsAllowed: false,
  language: undefined,
  deviceColumnsSelected: selectableColumnConstants.DEFAULT_DEVICE,
  skipColumnsSelected: selectableColumnConstants.DEFAULT_SKIP,
  licenses: undefined,
  serviceToolLoginOngoing: false,
  mfaEnabled: false,
  survey: undefined,
};

const parseColumns = (newColumnsData: [], columnType: "device" | "skip") => {
  const options = {
    device: {
      defaultColumns: selectableColumnConstants.DEFAULT_DEVICE,
      displayOrder: selectableColumnConstants.DEVICE_DISPLAY_ORDER,
    },
    skip: {
      defaultColumns: selectableColumnConstants.DEFAULT_SKIP,
      displayOrder: selectableColumnConstants.SKIP_DISPLAY_ORDER,
    },
  };

  if (newColumnsData.length > 0) {
    const newColumns = newColumnsData.map((column: any) =>
      parseInt(column.value)
    );
    return selectableColumnUtils.toDisplayOrder(
      newColumns,
      options[columnType].displayOrder
    );
  }
  return options[columnType].defaultColumns;
};

const users = (state = initialState, action: any): UsersState => {
  switch (action.type) {
    case FETCH_USER: {
      return { ...state };
    }
    case FETCH_USER_SUCCESS: {
      const userName = action.payload.user.name;
      const role = action.payload.user.role;
      const groups = action.payload.user.groups;
      const primaryAnalyticsGroup = action.payload.user.primaryAnalyticsGroup;
      const dataAnalyticsAllowed = action.payload.user.dataAnalyticsAllowed;
      const admin = action.payload.user.admin;
      const scaleAdmin = action.payload.user.scale_admin;
      const language = action.payload.user.language
        ? action.payload.user.language.value
        : undefined;
      const licenses = action.payload.user.licenses;
      const mfaEnabled = action.payload.user.mfaEnabled;
      const survey = action.payload.user.survey;
      const deviceColumnsSelected = parseColumns(
        action.payload.user.deviceColumns,
        "device"
      );

      const skipColumnsSelected = parseColumns(
        action.payload.user.skipColumns,
        "skip"
      );

      return {
        ...state,
        userName,
        role,
        groups,
        primaryAnalyticsGroup,
        admin,
        scaleAdmin,
        language,
        dataAnalyticsAllowed,
        deviceColumnsSelected,
        skipColumnsSelected,
        licenses,
        mfaEnabled,
        survey,
      };
    }
    case FETCH_USER_FAILURE: {
      return { ...state };
    }
    case DO_SERVICE_TOOL_LOGIN: {
      return { ...state, serviceToolLoginOngoing: true };
    }
    case DO_SERVICE_TOOL_LOGIN_SUCCESS:
    case DO_SERVICE_TOOL_LOGIN_FAILURE: {
      return { ...state, serviceToolLoginOngoing: false };
    }
    case DEVICES_COLUMNS_SELECTED_SUCCESS: {
      const deviceColumnsSelected = parseColumns(
        action.payload.user.deviceColumns,
        "device"
      );

      return { ...state, deviceColumnsSelected };
    }
    case DEVICES_COLUMNS_SELECTED_FAILURE: {
      return state;
    }
    case DEVICES_COLUMNS_SELECTED: {
      return state;
    }

    case SKIP_COLUMNS_SELECTED_SUCCESS: {
      const skipColumnsSelected = parseColumns(
        action.payload.user.skipColumns,
        "skip"
      );
      return { ...state, skipColumnsSelected };
    }
    case SKIP_COLUMNS_SELECTED_FAILURE: {
      return state;
    }
    case SKIP_COLUMNS_SELECTED: {
      return state;
    }
    case CHANGE_LANGUAGE_SUCCESS: {
      const language = action.payload.data.language.value;
      return { ...state, language };
    }
    case CHANGE_LANGUAGE_FAILURE: {
      return state;
    }
    case ENABLE_MFA_FOR_USER_SUCCESS: {
      return { ...state, mfaEnabled: true };
    }
    case DISABLE_MFA_FOR_USER_SUCCESS: {
      return { ...state, mfaEnabled: false };
    }
    case USER_SURVEY_ACTION_SUCCESS: {
      const surveyAction = action.payload.action;
      if (
        surveyAction === SurveyActions.DISMISSED ||
        surveyAction === SurveyActions.ANSWERED
      ) {
        return {
          ...state,
          survey: undefined, // Remove the survey from the state immediately if it is dismissed or answered
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default users;
