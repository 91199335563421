import { useEffect, useMemo, useState } from "react";
import * as TYPES from "../../constants/actionTypes";
import { addMonths, format, isFuture } from "date-fns";
import icon_arrow from "../../assets/icon-arrow-down-black.svg";
import { Cell } from "react-table";
import "../../css/override.css";
import styled, { css } from "styled-components";
import { Icon } from "../Common/Icon";
import { useSelector, useDispatch } from "react-redux";
import { getDateFnsLocale } from "../../utils/i18nUtils";
import { useTranslation } from "react-i18next";
import { DeviceType, MachineType } from "../../interfaces/types";
import * as XLSX from "xlsx";
import { getPrimaryStatus, getStatusMessage } from "../../utils/deviceStatus";
import { fillLevelToString } from "../../utils/utils";
import { Table, TableSettings } from "../../components/Common/Table";

const PeriodSelectorRowWrapper = styled.div`
  display: flex;
`;
const PeriodSelectorRow = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 15%; // attempt to center the element manually. Ugly.
`;

interface ArrowIconProps {
  disabled?: boolean;
}

const ArrowIcon = styled(Icon)<ArrowIconProps>`
  opacity: ${(props) => (props.disabled ? "0.3" : "auto")};
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
`;

interface HistoryDetailsTableProps {
  serialNumber: string;
  machineType: MachineType | undefined;
}

const DownloadHistoryButton = styled.button`
  align-self: flex-end;
  float: right;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #0d467d;
  color: #ffffff;
  height: 30px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  display: inline;
  margin-left: 16px;

  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

const PageSizeSelector = styled.select`
  display: inline-block;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 0.8em;
  font-weight: 400;
  outline-width: 0;
  padding: 5px 7px;
  max-width: 20%;
  min-width: 120px;
  margin: 12px auto;
  text-align: center;
`;

const DeviceHistoryTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const DeviceHistoryTableStyles = css`
  text-align: center;
  overflow-x: auto;
  border: 1px solid #e8ebee;
  .thead .th {
    padding: 5px;
  }
  .tbody .td {
    overflow: hidden;
    padding: 7px 5px;
    text-overflow: ellipsis;
    transition: 0.3s ease;
    transition-property: all;
    transition-property: width, min-width, padding, opacity;
    white-space: nowrap;
  }
  .tbody .tr {
    background: none;
    background-color: none;
    border: none;
    box-shadow: none;

    .td {
      background: #e8ebee;
    }

    &.even {
      background-color: unset;
      .td {
        background-color: #eff3f6;
      }
    }
  }
  .resizer {
    right: 0;
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    touch-action: none;
  }
`;

interface Coordinates {
  latitude: number | null;
  longitude: number | null;
}
const getCoordinates = (coords: Coordinates) => {
  if (coords.latitude && coords.longitude) {
    return `${coords.latitude}, ${coords.longitude}`;
  } else {
    return "";
  }
};

export function HistoryDetailsTable(props: HistoryDetailsTableProps) {
  const token = useSelector((state: any) => state.token.key);
  const history = useSelector((state: any) => state.devices.history);
  const loading = useSelector((state: any) => state.devices.loadingHistory);
  const language = useSelector((state: any) => state.users.language) || "en";
  const user = useSelector((state: any) => state.users);
  const [period, setPeriod] = useState(new Date());
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const dispatch = useDispatch();
  const dateFnsLocale = getDateFnsLocale(language);
  const { t } = useTranslation();

  const isEuropressUser = user.groups.includes("Europress");

  const changePeriod = (value: number) => () => {
    if (!isFuture(addMonths(period, value))) {
      setPeriod(addMonths(period, value));
    }
  };

  const onPageSizeChange = (pageSize: number) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const isDisabled = () => {
    return isFuture(addMonths(period, 1));
  };

  useEffect(() => {
    dispatch({
      type: TYPES.GET_DEVICE_HISTORY,
      payload: {
        token,
        serialNumber: props.serialNumber,
        period: format(period, "yyyy-MM"),
        page,
        pageSize,
      },
    });
  }, [period, page, pageSize, props.serialNumber, token, dispatch]);

  const hasAccessToReportDownload = () => {
    if (!user.licenses) {
      return false;
    }
    return user.licenses.insights || user.licenses.integrated;
  };

  // When period changes, reset page
  useEffect(() => {
    setPage(0);
  }, [period]);

  const tableTexts = {
    previousText: t(
      "deviceModal.historyDetails.table.previousText",
      "Previous"
    ),
    nextText: t("deviceModal.historyDetails.table.nextText", "Next"),
    loadingText: t(
      "deviceModal.historyDetails.table.loadingText",
      "Loading..."
    ),
    noDataText: t(
      "deviceModal.historyDetails.table.noDataText",
      "No rows found"
    ),
    pageText: t("deviceModal.historyDetails.table.pageText", "Page"),
    ofText: t("deviceModal.historyDetails.table.ofText", "of"),
    rowsText: t("deviceModal.historyDetails.table.rowsText", "rows"),
  };

  const downloadHistoryDetails = () => {
    const spreadsheetName = `${props.serialNumber} - History details.xlsx`;
    const spreadsheet = XLSX.utils.json_to_sheet(
      getDownloadableReportRows(history.rows)
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, spreadsheet, "History details");
    XLSX.writeFile(workbook, spreadsheetName);
  };

  const getDownloadableReportRows = (history: []) => {
    const rows = [];
    for (const row of history) {
      if (props.machineType === MachineType.Skip) {
        rows.push({
          [t("deviceModal.historyDetails.table.created", "Created")]: new Date(
            row["createdAt"]
          ).toLocaleString(),
          [t(
            "deviceModal.historyDetails.table.location",
            "Location"
          )]: getCoordinates(row["document"]),
        });
      }
      rows.push({
        [t("deviceModal.historyDetails.table.created", "Created")]: new Date(
          row["createdAt"]
        ).toLocaleString(),
        [t(
          "deviceModal.historyDetails.table.status",
          "Status"
        )]: getPrimaryStatus(row["document"]),
        [t(
          "deviceModal.historyDetails.table.fillLevel",
          "Fill level"
        )]: fillLevelToString(row["document"]["fillLevel"]),
        [t("deviceModal.historyDetails.table.online.label", "Online")]: row[
          "document"
        ]["onlineNow"]
          ? t("deviceModal.historyDetails.table.online.yes", "Yes")
          : t("deviceModal.historyDetails.table.online.no", "No"),
        [t(
          "deviceModal.historyDetails.table.statusMessage",
          "Status message"
        )]: getStatusMessage(row["document"], isEuropressUser),
        [t("deviceModal.historyDetails.table.rssi", "Rssi")]: row["document"][
          "signalQualityRSSI"
        ],
        [t(
          "deviceModal.historyDetails.table.lastOnline",
          "Last online"
        )]: new Date(row["document"]["lastOnline"]).toLocaleString(),
        [t(
          "deviceModal.historyDetails.table.location",
          "Location"
        )]: getCoordinates(row["document"]),
      });
    }
    return rows;
  };

  const columns = useMemo((): TableSettings["columns"] => {
    if (props.machineType === MachineType.Skip) {
      return [
        {
          Header: t("deviceModal.historyDetails.table.created", "Created"),
          accessor: "createdAt",
          Cell: (cell: Cell) => {
            return new Date(cell.value).toLocaleString();
          },
        },
        {
          Header: t("deviceModal.historyDetails.table.location", "Location"),
          accessor: "document",
          Cell: (cell: Cell) => {
            return getCoordinates(cell.value);
          },
        },
      ];
    }
    return [
      {
        Header: t("deviceModal.historyDetails.table.created", "Created"),
        accessor: "createdAt",
        Cell: (cell: Cell) => {
          return new Date(cell.value).toLocaleString();
        },
      },
      {
        Header: t("deviceModal.historyDetails.table.status", "Status"),
        id: "document.status",
        width: 75,
        accessor: (data: any) => getPrimaryStatus(data.document as DeviceType),
      },
      {
        Header: t("deviceModal.historyDetails.table.fillLevel", "Fill level"),
        id: "document.fillLevel",
        width: 100,
        accessor: (row: any) => fillLevelToString(row.document.fillLevel),
      },
      {
        Header: t("deviceModal.historyDetails.table.online.label", "Online"),
        accessor: "document.onlineNow",
        width: 75,
        Cell: (cell: Cell) => {
          return cell.value
            ? t("deviceModal.historyDetails.table.online.yes", "Yes")
            : t("deviceModal.historyDetails.table.online.no", "No");
        },
      },
      {
        Header: t(
          "deviceModal.historyDetails.table.statusMessage",
          "Status message"
        ),
        id: "document.statusBitmask",
        accessor: (data: any) =>
          getStatusMessage(data.document as DeviceType, isEuropressUser),
      },
      {
        Header: t("deviceModal.historyDetails.table.rssi", "Rssi"),
        width: 75,
        accessor: "document.signalQualityRSSI",
      },
      {
        Header: t("deviceModal.historyDetails.table.lastOnline", "Last online"),
        accessor: "document.lastOnline",
        Cell: (cell: Cell) => {
          return new Date(cell.value).toLocaleString();
        },
      },
      {
        Header: t("deviceModal.historyDetails.table.location", "Location"),
        accessor: "document",
        Cell: (cell: Cell) => {
          return getCoordinates(cell.value);
        },
      },
    ];
  }, [props.machineType, isEuropressUser, t]);

  // TODO: Typing (use PaginationControlsProps from Table.tsx?)
  const getManualPaginationProps = () => ({
    pageIndex: page,
    pageCount: history?.pages || 0,
    nextPage: () => setPage(page + 1),
    previousPage: () => setPage(page - 1),
    canNextPage: page < history?.pages - 1,
    canPreviousPage: page > 0,
  });

  return (
    <DeviceHistoryTableWrapper>
      {hasAccessToReportDownload() && (
        <DownloadHistoryButton
          onClick={downloadHistoryDetails}
          disabled={!history.rows || history.rows.length === 0}
        >
          {t("deviceModal.historyDetails.download", "Download as Excel")}
        </DownloadHistoryButton>
      )}
      <PeriodSelectorRowWrapper>
        <PeriodSelectorRow>
          <ArrowIcon
            src={icon_arrow}
            width={24}
            height={24}
            alt="previous month"
            rotate={90}
            cursor="pointer"
            onClick={changePeriod(-1)}
          />
          {format(period, "LLLL yyyy", { locale: dateFnsLocale })}
          <ArrowIcon
            src={icon_arrow}
            width={24}
            height={24}
            alt="next month"
            rotate={270}
            cursor="pointer"
            onClick={changePeriod(1)}
            disabled={isDisabled()}
          />
        </PeriodSelectorRow>
        <PageSizeSelector
          onChange={(e) => onPageSizeChange(parseInt(e.target.value))}
        >
          {[5, 10, 20, 25, 50, 100].map((v: number) => {
            return (
              <option value={v} selected={pageSize === v}>
                {v} rows per page
              </option>
            );
          })}
        </PageSizeSelector>
      </PeriodSelectorRowWrapper>
      {loading ? (
        "Loading..."
      ) : (
        <Table
          data={history.rows || []}
          pageSize={pageSize}
          paginate={true}
          manualPagination={true}
          manualPaginationProps={getManualPaginationProps()}
          sortable={false}
          columns={columns}
          tableStyles={DeviceHistoryTableStyles}
          resizeable={true}
          flexHeight={true}
          {...tableTexts}
        />
      )}
    </DeviceHistoryTableWrapper>
  );
}
